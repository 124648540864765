import type { DataSourceEntry } from "@/types";

export const usePriceColorEntries = async () => {
  try {
    const priceColors = useState<DataSourceEntry[]>(
      "productPriceColors",
      () => [],
    );

    if (priceColors.value.length > 0) {
      return priceColors.value;
    }

    const storyBlokApi = useStoryblokApi();
    const { data: categories } = await storyBlokApi.get(
      "cdn/datasource_entries",
      {
        datasource: "product-price-colours",
      },
    );

    const fetchedCategories = categories?.datasource_entries || [];
    priceColors.value = fetchedCategories;

    return fetchedCategories;
  } catch (error) {
    return [];
  }
};

export const useProductPriceColor = async (price: string) => {
  const colors = await usePriceColorEntries();

  const color = colors.find((item: DataSourceEntry) => item.name === price);

  return color ? color.value : "inherit";
};
